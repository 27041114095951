import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ngxDataTableConfig } from "app/shared/components/datatables/ngx-shared-table/ngx-models/ngx-datatable-config.model";

export const CASHING_DATATABLE_CONFIG: ngxDataTableConfig = {
    columnMode: ColumnMode.force,
    externalPaging: true,
    externalSorting: true,
    footerHeight: 56,
    headerHeight: 35,
    pageCount: 10,
    pageIndex: 0,
    reorderable: false,
    totalItems: 0,
    scrollbarH: true,
    rowHeight: 35,
    selectionType: SelectionType.single,
    sorts: [
        { prop: 'id', direction: 'desc' }
    ],
    messages: {
        emptyMessage: 'noData',
        totalMessage: '',
        selectedMessage: ''
    },
    unite: 'unite.cashing_out',
    selectionCheckbox: false,
    selectedRow: []
};
